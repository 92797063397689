<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import AppLogo from '@/modules/app/components/AppLogo.vue'
  import BaseLink from '@/modules/base/components/BaseLink.vue'
  import { Route } from '@/modules/base/config/routesConfig'
  import MdiChevronLeft from '~icons/mdi/chevron-left'

  interface Props {
    isMenuOpen: boolean
    setIsMenuOpen: (value: boolean) => void
  }

  withDefaults(defineProps<Props>(), {
    isMenuOpen: false,
  })

  const { t } = useI18n()
</script>

<template>
  <div class="relative">
    <div class="overflow-hidden">
      <BaseLink class="pl-2" :to="{ name: Route.MyTasks }">
        <AppLogo :square="!isMenuOpen" />
        <span class="sr-only">
          {{
            isMenuOpen ? t('global.navigation.close') : t('global.menu.open')
          }}
        </span>
      </BaseLink>
    </div>
    <BaseButton
      aria-controls="the-menu"
      :aria-expanded="isMenuOpen"
      class="absolute -right-5 flex h-7 w-7 items-center justify-center rounded-full bg-gray-300"
      data-testid="the-header-open-menu"
      @click="setIsMenuOpen(!isMenuOpen)"
    >
      <MdiChevronLeft
        class="absolute size-7 text-gray-600"
        :class="{
          '-scale-x-100': !isMenuOpen,
        }"
      />
    </BaseButton>
  </div>
</template>
