import { RouteRecordRaw } from 'vue-router'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Feature } from '../ability/composable/useFeatures'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('settings'),
    name: Route.SettingsDefault,
    redirect: { name: Route.SettingsUsers },
    children: [
      {
        path: 'organization',
        // Keep for backwards compatibility
        alias: 'general-info',
        name: Route.SettingsOrganization,
        component: () =>
          import('@/modules/settings/views/SettingsOrganization.vue'),
        meta: {
          requireFeatures: [Feature.SettingsOrganization],
        },
      },
      {
        path: 'locations',
        name: Route.SettingsLocations,
        component: () =>
          import('@/modules/settings/views/SettingsLocations.vue'),
        meta: {
          requireFeatures: [Feature.SettingsLocations],
        },
      },
      {
        path: 'my-account',
        name: Route.SettingsMyAccount,
        component: () =>
          import('@/modules/settings/views/SettingsMyAccount.vue'),
      },
      {
        path: 'users',
        name: Route.SettingsUsersDefault,
        meta: {
          requireFeatures: [Feature.SettingsUsers],
        },
        redirect: { name: Route.SettingsUsers },
        children: [
          {
            path: '',
            component: () =>
              import('@/modules/settings/views/SettingsUsers.vue'),
            name: Route.SettingsUsers,
          },
          {
            path: ':id',
            name: Route.SettingsUsersDetail,
            component: () =>
              import('@/modules/settings/views/SettingsUsersDetail.vue'),
            meta: {
              companySwitchRedirect: {
                name: Route.SettingsUsers,
              },
            },
          },
        ],
      },
    ],
  },
]
