import { computed } from 'vue'
import { useCurrentCompany } from '@/modules/base/composable/useCurrentCompany'

/**
 * Make sure to keep this enum in sync with the backend flags
 */
export enum Feature {
  Settings = 'settings',
  SettingsUsers = 'settingsUsers',
  SettingsLocations = 'settingsLocations',
  SettingsOrganization = 'settingsOrganization',
  AuditTrail = 'auditTrail',
  Reporting = 'reporting',
  ReportingMateriality = 'reportingMateriality',
  Footprint = 'footprint',
  FootprintDataEntry = 'footprintDataEntry',
  FootprintResult = 'footprintResult',
  FootprintActivities = 'footprintActivities',
  Reduction = 'reduction',
  ReductionRoadmap = 'reductionRoadmap',
  ReductionInsights = 'reductionInsights',
  ReductionAssessmentActions = 'reductionAssessmentActions',
  ReductionAssessmentCalculations = 'reductionAssessmentCalculations',
  ReductionAssessmentReductionPath = 'reductionAssessmentReductionPath',
  ReductionAssessmentEmissions = 'reductionAssessmentEmissions',
  StatusBoard = 'statusBoard',
}

export type FeaturesSubject = {
  __typename: 'Features'
  companyId: number
} & Partial<Record<Feature, undefined>>

export const getFeatures = (companyId: number): FeaturesSubject => ({
  __typename: 'Features',
  companyId,
})

export const useFeatures = () => {
  const { currentCompanyId } = useCurrentCompany()

  return {
    features: computed(() => getFeatures(currentCompanyId.value)),
  }
}
