<script setup lang="ts">
  import { computed } from 'vue'
  import {
    Feature,
    useFeatures,
  } from '@/modules/ability/composable/useFeatures'
  import TheCompanySwitcher from '@/modules/app/components/TheCompanySwitcher.vue'
  import TheMenuAppLogo from '@/modules/app/components/TheMenuAppLogo.vue'
  import TheMenuUser from '@/modules/app/components/TheMenuUser.vue'
  import { useLayoutStore } from '@/modules/base/stores/useLayoutStore'
  import TheMenuDashboard from './TheMenuDashboard.vue'
  import TheMenuFaq from './TheMenuFaq.vue'
  import TheMenuFootprint from './TheMenuFootprint.vue'
  import TheMenuMyTasks from './TheMenuMyTasks.vue'
  import TheMenuReduction from './TheMenuReduction.vue'
  import TheMenuReporting from './TheMenuReporting.vue'
  import TheMenuSettings from './TheMenuSettings.vue'

  const layout = useLayoutStore()
  const isOpen = computed(() => layout.isMenuOpen)

  const { features } = useFeatures()
</script>

<template>
  <div class="z-30 flex bg-blue-800">
    <nav
      id="the-menu"
      class="flex h-screen max-h-screen flex-col pl-3 text-white transition-[width] duration-300 ease-in-out"
      :class="isOpen ? 'w-60 pr-2' : 'w-20 pr-3'"
      data-testid="the-menu"
    >
      <p class="sr-only">
        {{ $t('global.menu.title') }}
      </p>

      <TheMenuAppLogo
        class="pb-12 pt-3.5"
        :is-menu-open="layout.isMenuOpen"
        :set-is-menu-open="layout.setIsMenuOpen"
      />

      <div class="grid grid-cols-[1.4rem,1fr] gap-x-8 gap-y-2">
        <TheMenuMyTasks class="mb-2" />

        <hr class="col-span-2 w-full border-t border-gray-600" />

        <div class="mt-2 h-12 min-w-52 pl-2" data-testid="company-switcher">
          <TheCompanySwitcher :is-menu-open="isOpen" />
        </div>

        <hr class="col-span-2 w-full border-t border-gray-600" />
      </div>
      <div
        class="gc-scrollbar-menu gc-global-scrollbar-thin gc-global-scrollbar-thin gutter-stable grid grid-cols-[1.4rem,1fr] gap-x-8 gap-y-2 overflow-y-auto overflow-x-hidden pb-5 pt-2"
      >
        <template v-if="$can('show', features, Feature.StatusBoard)">
          <TheMenuDashboard />
        </template>

        <template v-if="$can('show', features, Feature.Footprint)">
          <TheMenuFootprint :is-menu-open="isOpen" />
        </template>

        <template v-if="$can('show', features, Feature.Reduction)">
          <TheMenuReduction :is-menu-open="isOpen" />
        </template>

        <template v-if="$can('show', features, Feature.Reporting)">
          <TheMenuReporting :is-menu-open="isOpen" />
        </template>

        <TheMenuSettings :is-menu-open="isOpen" />

        <hr class="col-span-2 w-full border-t border-gray-600" />

        <TheMenuFaq />
      </div>

      <TheMenuUser
        class="mt-auto pb-4 pl-2"
        data-testid="menu-user-profile-button"
        :is-menu-open="isOpen"
      />
    </nav>
  </div>
</template>

<style scoped>
  .gc-scrollbar-menu {
    /**
     * Foreground and Background colors are set to the same value to make the element appear invisible
     * without affecting the layout sizing.
     */
    scrollbar-color: theme('colors.blue.800') theme('colors.blue.800');

    &::-webkit-scrollbar-thumb {
      @apply rounded-full bg-blue-800;
    }

    &::-webkit-scrollbar-track {
      @apply rounded-full bg-blue-800;
    }

    &::-webkit-scrollbar-corner {
      @apply bg-blue-800;
    }

    &:hover {
      scrollbar-color: theme('colors.gray.200') theme('colors.blue.800');

      &::-webkit-scrollbar-thumb {
        @apply bg-gray-200;
      }
    }
  }

  .gc-global-main-view-transition-name {
    view-transition-name: main;
  }

  .gc-global-base-container-transition-name {
    view-transition-name: base-container;
  }
</style>
