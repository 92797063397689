import * as Sentry from '@sentry/vue'

import router from '../router'
import type { App, Plugin } from 'vue'

const ENABLED_DOMAINS = [
  /^https:\/\/climatecontrolcenter\.com/,
  /^https:\/\/staging.climatecontrolcenter\.com/,
  /^https:\/\/center.greenzero\.eu/,
]

export const sentryPlugin: Plugin = {
  install(app: App) {
    Sentry.init({
      app,
      environment: window.App?.ENV.SENTRY_ENV,
      dsn: window.App?.ENV.SENTRY_FRONTEND_DSN,
      trackComponents: true,
      release: window.App?.ENV.REVISION,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
      tracesSampleRate: 0.1,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: ENABLED_DOMAINS,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
    })
  },
}

export function sentryErrorHandler(error: Error) {
  Sentry.captureException(error)
}
