<script setup lang="ts">
  import {
    Feature,
    useFeatures,
  } from '@/modules/ability/composable/useFeatures'
  import { Route } from '@/modules/base/config/routesConfig'
  import MdiThermometerChevronDown from '~icons/mdi/thermometer-chevron-down'
  import MenuLink from './MenuLink.vue'

  interface Props {
    isMenuOpen: boolean
  }

  const { features } = useFeatures()

  withDefaults(defineProps<Props>(), {
    isMenuOpen: false,
  })
</script>

<template>
  <MenuLink
    data-testid="the-menu-link-reduction"
    match-exact
    :to="{ name: Route.Actions }"
  >
    <template #icon>
      <MdiThermometerChevronDown class="size-6" />
    </template>
  </MenuLink>
  <template v-if="isMenuOpen">
    <MenuLink
      v-if="$can('show', features, Feature.ReductionRoadmap)"
      data-testid="the-menu-link-reduction-roadmap"
      submenu
      :to="{ name: Route.ActionsRoadmap }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.ReductionInsights)"
      data-testid="the-menu-link-reduction-insights"
      submenu
      :to="{ name: Route.Insights }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.ReductionAssessmentActions)"
      data-testid="the-menu-link-reduction-actions"
      submenu
      :to="{ name: Route.AssessmentActions }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.ReductionAssessmentCalculations)"
      data-testid="the-menu-link-reduction-calculations"
      submenu
      :to="{ name: Route.AssessmentCalculations }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.ReductionAssessmentReductionPath)"
      data-testid="the-menu-link-reduction-net-zero"
      submenu
      :to="{ name: Route.AssessmentReductionPath }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.ReductionAssessmentEmissions)"
      data-testid="the-menu-link-reduction-emissions"
      submenu
      :to="{ name: Route.AssessmentEmissions }"
    />
  </template>
</template>
