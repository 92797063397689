import { z } from 'zod'
import { withAbility } from '@/modules/ability/utils/abilitySchema'
import { locationSchema, locationMinimalSchema } from './useLocations.types'

export enum AuthenticationProvider {
  Email = 'email',
  EntraId = 'entra_id',
}

/**
 * See `app/models/concerns/has_onboarding_state_machine.rb` for reference
 */
export enum OnboardingStatus {
  // TODO: rename to "companySetup" https://github.com/GlobalChanger/global_changer_web/pull/1797#discussion_r1383102919
  NotStarted = 'not_started',
  CategoriesCustomization = 'categories_customization',
  QuickstartPrompt = 'quickstart_prompt',
  Finished = 'finished',
}

export enum GlobalEmissionsTradingScheme {
  NoScheme = 'no_scheme',
  EUETS = 'eu_ets',
  NEHS = 'nehs',
  Other = 'other',
}

export enum EnergyIntensiveSector {
  NoIntensiveSector = 'no_intensive_sector',
  Agriculture = 'agriculture',
  Mining = 'mining',
  Manufacturing = 'manufacturing',
  EnergySupply = 'energy_supply',
  WaterSupply = 'water_supply',
  Construction = 'construction',
  Wholesale = 'wholesale',
  Transportation = 'transportation',
  RealEstate = 'real_estate',
}

export const companySchema = withAbility(
  'Company',
  z.strictObject({
    authenticationProvider: z.nativeEnum(AuthenticationProvider).nullable(),
    baseYear: z.number().nullable(),
    industry: z.string().nullable(),
    createdAt: z.string(),
    discountRate: z.number().nullable(),
    hasCurrentUserWriteAccess: z.boolean(),
    hasLinearReduction: z.boolean(),
    hasResetOnboarding: z.boolean(),
    id: z.number(),
    linearReduction: z.number().nullable(),
    mainLocation: locationSchema.nullable(),
    name: z.string().nullable(),
    netZeroYear: z.number().nullable(),
    onboardingStatus: z.nativeEnum(OnboardingStatus),
    depth: z.number(),
    materialityThreshold: z.number().nullish(),
    entraIdCompanyName: z.string().nullable(),
    customGlobalEmissionsTradingScheme: z.string().nullable(),
    emissionsTradingSchemeScope1EmissionsPercent: z.number().nullable(),
    energyIntensiveSector: z.nativeEnum(EnergyIntensiveSector).nullable(),
    globalEmissionsTradingScheme: z
      .nativeEnum(GlobalEmissionsTradingScheme)
      .nullable(),
  })
)
export type Company = z.infer<typeof companySchema>

export const companyMinimalSchema = withAbility(
  'Company',
  companySchema
    .pick({
      id: true,
      name: true,
      baseYear: true,
      netZeroYear: true,
      onboardingStatus: true,
      depth: true,
    })
    .extend({
      mainLocation: locationMinimalSchema.nullable(),
    })
)
export type CompanyMinimal = z.infer<typeof companyMinimalSchema>

export const companyMutationDataSchema = companySchema
  .partial()
  .pick({
    name: true,
    baseYear: true,
    discountRate: true,
    hasLinearReduction: true,
    linearReduction: true,
    netZeroYear: true,
    industry: true,
    materialityThreshold: true,
    globalEmissionsTradingScheme: true,
    energyIntensiveSector: true,
    customGlobalEmissionsTradingScheme: true,
    emissionsTradingSchemeScope1EmissionsPercent: true,
    entraIdCompanyName: true,
  })
  .extend({
    mainLocationAttributes: locationSchema.partial().nullish(),
  })
  .strip()

export type CompanyMutationData = z.infer<typeof companyMutationDataSchema>

export const newCompanyDataSchema = z.strictObject({
  parentId: z.number(),
  mainLocationAttributes: locationSchema.pick({ name: true, country: true }),
})

export type NewCompanyData = z.infer<typeof newCompanyDataSchema>

export interface CompanyTreeFilterParams {
  includeCurrentCompany?: boolean
  includeChildCompanies?: boolean
  includeDescendantCompanies?: boolean
  includeAncestors?: boolean
  includeAllCompanies?: boolean
}
