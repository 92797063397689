import { z } from 'zod'

export const locationSchema = z.strictObject({
  id: z.number(),
  city: z.string().nullable(),
  companyId: z.number().nullable(),
  name: z.string().nullable(),
  country: z.string().nullable(),
  mainLocation: z.boolean(),
  postalCode: z.string().nullable(),
  street: z.string().nullable(),
  companyActionsCount: z.number().nullable(),
  userIds: z.array(z.number()).nullable(),
})

export type Location = z.infer<typeof locationSchema>

export const locationMinimalSchema = locationSchema.pick({
  id: true,
  name: true,
  country: true,
})
