import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCompanyStore = defineStore('company-store', () => {
  const name = ref<string>('')

  function setName(newName: string) {
    name.value = newName
  }

  return {
    name,
    setName,
  }
})
