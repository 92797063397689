import { z } from 'zod'
import { withAbility } from '@/modules/ability/utils/abilitySchema'

export enum RoleName {
  Admin = 'admin',
  Manager = 'manager',
  Viewer = 'viewer',
  Member = 'member',
}

export const roleNamePriority: Record<RoleName, number> = {
  [RoleName.Admin]: 40,
  [RoleName.Manager]: 30,
  [RoleName.Viewer]: 20,
  [RoleName.Member]: 10,
}
export enum RoleModule {
  Footprint = 'footprint',
  Reduction = 'reduction',
  Reporting = 'reporting',
}

export const roleSchema = withAbility(
  'Role',
  z.strictObject({
    id: z.number().nullable(),
    userId: z.number(),
    companyId: z.number(),
    name: z.nativeEnum(RoleName),
    module: z.nativeEnum(RoleModule),
    inherited: z.boolean(),
  })
)

export type Role = z.infer<typeof roleSchema>

export const roleMutationSchema = roleSchema
  .pick({
    name: true,
    module: true,
    companyId: true,
  })
  .strip()
export type RoleMutation = z.infer<typeof roleMutationSchema>
