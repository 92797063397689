<script setup lang="ts">
  import {
    Feature,
    useFeatures,
  } from '@/modules/ability/composable/useFeatures'
  import { Route } from '@/modules/base/config/routesConfig'
  import MdiCalculatorVariantOutline from '~icons/mdi/calculator-variant-outline'
  import MenuLink from './MenuLink.vue'

  interface Props {
    isMenuOpen: boolean
  }

  const { features } = useFeatures()

  withDefaults(defineProps<Props>(), {
    isMenuOpen: false,
  })
</script>

<template>
  <MenuLink
    data-testid="the-menu-link-footprint"
    match-exact
    :to="{ name: Route.Footprint }"
  >
    <template #icon>
      <MdiCalculatorVariantOutline class="size-6" />
    </template>
  </MenuLink>
  <template v-if="isMenuOpen">
    <MenuLink
      v-if="$can('show', features, Feature.FootprintDataEntry)"
      data-testid="the-menu-link-footprint-data-entry"
      submenu
      :to="{ name: Route.FootprintEntry }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.FootprintResult)"
      data-testid="the-menu-link-footprint-result"
      submenu
      :to="{ name: Route.FootprintResult }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.FootprintActivities)"
      data-testid="the-menu-link-footprint-activities"
      submenu
      :to="{ name: Route.FootprintActivities }"
    />
  </template>
</template>
