<script setup lang="ts" generic="T extends Date | [Date, Date]">
  import Button from 'primevue/button'
  import Menu from 'primevue/menu'
  import { MenuItem } from 'primevue/menuitem'
  import { computed, ref, useTemplateRef, watch } from 'vue'
  import MenuDotsIcon from '@/assets/icons/MenuDots.svg'
  import { MenuOption } from './baseMenu'

  const { options } = defineProps<{
    options: MenuOption[]
  }>()

  const menu = useTemplateRef('menu')

  const emit = defineEmits<{ toggled: [isOpen: boolean] }>()

  const isOpen = ref(false)

  watch(isOpen, (value) => {
    emit('toggled', value)
  })

  const onToggle = (event: Event) => {
    isOpen.value = !isOpen.value
    menu.value?.toggle(event)
  }

  defineExpose({ onToggle })

  const primeOptions = computed<MenuItem[]>(() =>
    options.map((option) => ({
      label: option.title,
      customIcon: option.icon,
      command: option.action,
      separator: option.separator,
    }))
  )
</script>

<template>
  <Button
    v-bind="$attrs"
    aria-controls="overlay_menu"
    aria-haspopup="true"
    class="base-control disabled:not-allowed hover:opacity-control p-0"
    data-testid="base-menu-button"
    variant="text"
    @click="onToggle"
  >
    <slot>
      <MenuDotsIcon />
    </slot>
  </Button>
  <Menu
    id="overlay_menu"
    ref="menu"
    data-testid="base-menu-panel"
    :model="primeOptions"
    :popup="true"
  >
    <template #itemicon="{ item }">
      <Component :is="item.customIcon" class="size-5" />
    </template>
  </Menu>
</template>
