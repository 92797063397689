<script setup lang="ts">
  import { computed, ref, useTemplateRef } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import { useAuth } from '@/api/useAuth'
  import { useCurrentCompanyQuery } from '@/api/useCompany'
  import IconAccount from '@/assets/icons/Account.svg'
  import IconArrowDown from '@/assets/icons/ArrowDown.svg'
  import IconLogout from '@/assets/icons/Logout.svg'
  import { MenuOption } from '@/modules/base/components/baseMenu'
  import BaseMenuDropdown from '@/modules/base/components/BaseMenuDropdown.vue'
  import BaseUserAvatar from '@/modules/base/components/BaseUserAvatar.vue'
  import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'
  import { Route } from '@/modules/base/config/routesConfig'
  import { useResetOnboarding } from '@/modules/settings/composable/useResetOnboarding'
  import { ContactInfo } from '@/modules/shared/config/contactInfoConfig'

  const { currentUser, currentUserFullName } = useCurrentUser()
  const { logout } = useAuth()

  const { t } = useI18n()
  const { data: company } = useCurrentCompanyQuery()
  const { resetOnboardingWithConfirmation } = useResetOnboarding()
  const router = useRouter()
  const options = computed<MenuOption[]>(() =>
    [
      {
        title: t('global.header.logout'),
        action: logout,
        icon: IconLogout,
      },
      {
        title: t('global.header.myAccount'),
        action: () => router.push({ name: Route.SettingsMyAccount }),
        icon: IconAccount,
      },
      {
        separator: true,
      },
      {
        title: t('global.header.sendEmail'),
        action: () => window.open(ContactInfo.EmailTo, '_blank'),
      },
      {
        title: t('global.header.scheduleCall'),
        action: () => window.open(ContactInfo.CalendlyUrl, '_blank'),
      },
      {
        title: t('global.header.termsAndConditions'),
        action: () =>
          window.open(t('global.header.termsAndConditionsLink'), '_blank'),
      },
      {
        title: t('global.header.privacyPolicy'),
        action: () =>
          window.open(t('global.header.privacyPolicyLink'), '_blank'),
      },
      ...(company.value?.hasResetOnboarding
        ? [
            {
              title: t('global.menu.resetOnboarding'),
              action: resetOnboardingWithConfirmation,
            },
          ]
        : []),
    ].reverse()
  )

  interface Props {
    isMenuOpen: boolean
  }

  withDefaults(defineProps<Props>(), {
    isMenuOpen: false,
  })

  const isOpen = ref(false)

  const dropdown = useTemplateRef('dropdown')

  const openDropdown = (event: Event) => {
    dropdown.value?.onToggle(event)
  }
</script>

<template>
  <div class="flex">
    <BaseUserAvatar
      v-if="currentUser"
      :apply-theme="false"
      class="h-9 self-center text-white"
      data-testid="the-header-user-menu"
      :user-id="currentUser?.id"
      @click="openDropdown"
    />
    <BaseMenuDropdown
      ref="dropdown"
      class="hover:bg-transparent"
      :options
      panel-class="w-60"
      toggle-class="p-1.5"
      @toggled="isOpen = $event"
    >
      <div
        class="flex max-w-48 gap-2 overflow-hidden text-ellipsis whitespace-nowrap"
      >
        <h1
          v-if="currentUser"
          class="py-control-block text-md max-w-[7.5rem] overflow-hidden text-ellipsis whitespace-nowrap pl-3.5 font-bold text-white"
        >
          {{ currentUserFullName }}
        </h1>

        <IconArrowDown
          class="-scale-y-100 self-center fill-current text-white"
          :class="[isOpen ? '-scale-y-100' : 'scale-y-100']"
        />
      </div>
    </BaseMenuDropdown>
  </div>
</template>
