import { findIndex } from 'lodash'

export type ItemWithChildren<T> = { children: ItemWithChildren<T>[] } & T

export const buildTree = <T extends { depth: number }>(
  itemsDepthFirst: T[]
): ItemWithChildren<T> => {
  if (itemsDepthFirst.length === 0) {
    throw new Error('Array is empty')
  }

  const [root, ...rest] = itemsDepthFirst

  if (rest.some((item) => item.depth <= root.depth)) {
    throw new Error('Items are not in depth-first order')
  }

  const isChild = (item: T) => item.depth === root.depth + 1
  const children = []

  while (rest.length > 0) {
    let nextChildIndex = findIndex(rest, isChild, 1)
    nextChildIndex = nextChildIndex === -1 ? rest.length : nextChildIndex

    const child = buildTree(rest.splice(0, nextChildIndex))
    children.push(child)
  }

  return { ...root, children }
}
