import { NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { useAbility } from '@/modules/ability/composable/useAbility'
import { Feature, getFeatures } from '@/modules/ability/composable/useFeatures'
import { appsignal } from '@/modules/base/composable/useAppsignal'
import { getCompanyId } from '@/modules/base/composable/useCurrentCompany'
import { Route } from '@/modules/base/config/routesConfig'

export const featuresAccessGuard: NavigationGuard = async (to) => {
  try {
    const companyId = getCompanyId(to)
    const features = getFeatures(companyId)
    const ability = useAbility()

    await waitForAbilityRules(ability)

    return checkMatchedRoutesFeatureAccess(to, (feature) =>
      ability.can('show', features, feature)
    )
  } catch (error) {
    appsignal.send(error as Error)
    return false
  }
}

const waitForAbilityRules = async (ability: ReturnType<typeof useAbility>) =>
  new Promise<void>((resolve) => {
    const unsubscribe = ability.on('updated', () => {
      unsubscribe()
      resolve()
    })

    if (ability.rules.length > 0) {
      unsubscribe()
      resolve()
    }
  })

export const checkMatchedRoutesFeatureAccess = (
  to: RouteLocationNormalized,
  can: (feature: Feature) => boolean
) => {
  for (const match of to.matched) {
    const missingFeature = match.meta.requireFeatures?.find(
      (feature) => !can(feature)
    )

    if (!missingFeature) {
      continue
    }

    return {
      ...to,
      name: Route.MyTasks,
    }
  }

  return true
}
