<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useCurrentUsersCompaniesQuery } from '@/api/useCompany'
  import IconArrowDown from '@/assets/icons/ArrowDown.svg'
  import { useAbility } from '@/modules/ability/composable/useAbility'
  import { useCompanyStore } from '@/modules/app/stores/useCompanyStore'
  import BaseLink from '@/modules/base/components/BaseLink.vue'
  import BaseModal from '@/modules/base/components/BaseModal.vue'
  import BasePopover from '@/modules/base/components/BasePopover.vue'
  import NestedDisclosure from '@/modules/base/components/NestedDisclosure.vue'
  import { buildTree } from '@/modules/base/composable/useTree'
  import LayoutButtonAdd from '@/modules/base/layouts/LayoutButtonAdd.vue'
  import LayoutScrollbarShade from '@/modules/base/layouts/LayoutScrollbarShade.vue'
  import { maybe } from '@/modules/base/utils/maybe'
  import CreateNewCompanyForm from './CreateNewCompanyForm.vue'

  interface Props {
    isMenuOpen: boolean
  }

  withDefaults(defineProps<Props>(), {
    isMenuOpen: false,
  })

  const { data: companies } = useCurrentUsersCompaniesQuery({
    includeCurrentCompany: true,
    includeDescendantCompanies: true,
  })
  const companyTree = computed(() => maybe(buildTree, companies.value))

  const options = computed(() => {
    return (
      companies.value?.map((company) => ({
        depth: company.depth,
        title: company.name,
        to: {
          params: {
            companyId: company.id.toString(),
          },
        },
      })) ?? []
    )
  })

  const companyTitle = computed(() => {
    return companies.value?.slice(0, 1).shift()?.name
  })

  const companyInitials = computed(() => {
    if (!title.value || typeof title.value !== 'string') {
      return ''
    }

    const parts = title.value.trim().split(/\s+/)
    if (parts.length === 0) {
      return ''
    }

    const initials = parts
      .slice(0, 2)
      .map((part) => part[0])
      .join('')

    return initials.toUpperCase()
  })

  const createCompanyParentId = ref<number | null>(null)

  const route = useRoute()
  const title = computed(() => {
    return (
      options.value.find(({ to }) => {
        return to.params.companyId === route.params?.companyId
      })?.title ?? options.value[0]?.title
    )
  })

  const openIds = ref(new Set<number>([]))

  const { can } = useAbility()
  const isMultiCompanyFeatureEnabled = computed(() => {
    return (
      (options.value && (companies.value?.length ?? 0) > 1) ||
      can('create', 'Company')
    )
  })

  const { setName } = useCompanyStore()
  watch(title, (value) => setName(value || ''))
</script>

<template>
  <template v-if="isMenuOpen">
    <BasePopover
      v-if="isMultiCompanyFeatureEnabled"
      button-class="flex gap-2  justify-center items-center text-md font-bold"
    >
      <template #button="{ open }">
        <h1
          class="py-control-block text-md max-w-36 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
        >
          {{ title }}
        </h1>
        <IconArrowDown
          class="fill-current"
          :class="[open ? '-scale-y-100' : 'scale-y-100']"
        />
      </template>
      <template #panel="{ close }">
        <div class="overflow-hidden rounded-[inherit]">
          <LayoutScrollbarShade class="text-black-var pr-1">
            <div
              class="gc-global-scrollbar gc-global-scrollbar-thin gc-global-scrollbar-white-var-30 text-white-var max-h-[30rem] overflow-y-auto px-3"
            >
              <div v-focus class="w-80" tabindex="0" />

              <NestedDisclosure
                v-if="companyTree"
                v-slot="{ item: company }"
                v-model:open-ids="openIds"
                :nested-item="companyTree"
              >
                <div class="flex w-full items-center justify-between">
                  <BaseLink
                    class="py-control-block pl-0"
                    data-testid="header-company-switcher-link"
                    :to="{ params: { companyId: company.id } }"
                    @click="close"
                  >
                    {{ company.name }}
                  </BaseLink>
                  <LayoutButtonAdd
                    v-if="$can('create', 'Company')"
                    v-tooltip="{
                      content: $t('global.header.createCompanyTooltip'),
                      options: { placement: 'right' },
                    }"
                    data-testid="header-company-switcher-create-company"
                    icon-class="size-4"
                    @click="createCompanyParentId = company.id"
                  />
                </div>
              </NestedDisclosure>
            </div>
          </LayoutScrollbarShade>
        </div>
      </template>
    </BasePopover>

    <div v-else>
      <h1
        class="py-control-block text-md overflow-hidden text-ellipsis whitespace-nowrap pl-2 font-bold"
      >
        {{ companyTitle }}
      </h1>
    </div>
    <BaseModal
      v-if="$can('create', 'Company')"
      v-slot="{ close }"
      container-class="!w-100"
      :open="createCompanyParentId != null"
      @close="createCompanyParentId = null"
    >
      <CreateNewCompanyForm
        v-if="createCompanyParentId != null"
        :parent-company-id="createCompanyParentId"
        @close="close"
      />
    </BaseModal>
  </template>
  <template v-else>
    <div
      class="flex aspect-square h-9 items-center justify-center self-center rounded-full bg-gray-300"
    >
      <p class="uppercase text-gray-900">
        {{ companyInitials }}
      </p>
    </div>
  </template>
</template>
