<script setup lang="ts">
  import {
    Feature,
    useFeatures,
  } from '@/modules/ability/composable/useFeatures'
  import { Route } from '@/modules/base/config/routesConfig'
  import MdiCogOutline from '~icons/mdi/cog-outline'

  import MenuLink from './MenuLink.vue'

  interface Props {
    isMenuOpen: boolean
  }

  const { features } = useFeatures()

  withDefaults(defineProps<Props>(), {
    isMenuOpen: false,
  })
</script>

<template>
  <MenuLink
    data-testid="the-menu-link-settings"
    match-exact
    :to="{ name: Route.SettingsDefault }"
  >
    <template #icon>
      <MdiCogOutline class="size-6" />
    </template>
  </MenuLink>
  <template v-if="isMenuOpen">
    <MenuLink
      v-if="$can('show', features, Feature.SettingsUsers)"
      data-testid="the-menu-link-settings-users"
      submenu
      :to="{ name: Route.SettingsUsers }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.SettingsLocations)"
      data-testid="the-menu-link-settings-locations"
      submenu
      :to="{ name: Route.SettingsLocations }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.SettingsOrganization)"
      data-testid="the-menu-link-settings-organization"
      submenu
      :to="{ name: Route.SettingsOrganization }"
    />
    <MenuLink
      v-if="$can('show', features, Feature.AuditTrail)"
      data-testid="the-menu-link-audit"
      submenu
      :to="{ name: Route.Audit }"
    />
  </template>
</template>
