import { useMutation } from '@tanstack/vue-query'
import { z } from 'zod'
import { useDefaultMutationHandler } from '@/modules/base/composable/useDefaultMutationHandler'
import {
  CompanyMutationData,
  companyMutationDataSchema,
  CompanyTreeFilterParams,
  companySchema,
  companyMinimalSchema,
  OnboardingStatus,
  NewCompanyData,
  CompanyMinimal,
} from './useCompany.types'
import {
  universalTypedFetch,
  stringifyBody,
  composeTypedQueryWithCompanyId,
  composeTypedQuery,
  useComposeInvalidation,
} from './utils/factory'
import { createUrl, Endpoint, useUrl } from './utils/url'
import type { UseQueryOptions } from '@tanstack/vue-query'

export const useCurrentCompanyQuery = composeTypedQueryWithCompanyId(
  [],
  companySchema
)

export const useCurrentUsersCompaniesQuery = <
  DataTransformed = CompanyMinimal[]
>(
  params: CompanyTreeFilterParams = {
    includeCurrentCompany: true,
    includeChildCompanies: false,
    includeDescendantCompanies: false,
  },
  options?: Partial<UseQueryOptions<CompanyMinimal[], unknown, DataTransformed>>
) =>
  composeTypedQuery(
    [Endpoint.Companies, params],
    companyMinimalSchema.array()
  )(options)

export const useCompaniesQuery = <DataTransformed = CompanyMinimal[]>(
  params: CompanyTreeFilterParams = {
    includeCurrentCompany: true,
    includeChildCompanies: false,
    includeDescendantCompanies: false,
  },
  options?: Partial<UseQueryOptions<CompanyMinimal[], unknown, DataTransformed>>
) =>
  composeTypedQueryWithCompanyId(
    [Endpoint.ListCompanies, params],
    companyMinimalSchema.array()
  )(options)

export const useCompaniesOnboardedQuery = (
  params: CompanyTreeFilterParams = {
    includeCurrentCompany: true,
    includeChildCompanies: false,
    includeDescendantCompanies: false,
  }
) =>
  useCompaniesQuery(params, {
    select: (data) =>
      data.filter(
        ({ onboardingStatus }) => onboardingStatus === OnboardingStatus.Finished
      ),
  })

export const useMutationCompanyEdit = () => {
  const { invalidate } = useCompanyInvalidation()
  const { createUrlWithCompanyId } = useUrl()
  const { defaultMutationHandler } = useDefaultMutationHandler()

  return useMutation({
    mutationFn(company: CompanyMutationData) {
      const url = createUrlWithCompanyId()
      return universalTypedFetch(url, z.undefined(), {
        method: 'PATCH',
        body: stringifyBody({
          company: companyMutationDataSchema.parse(company),
        }),
      })
    },
    onSettled: invalidate,
    ...defaultMutationHandler,
  })
}

export const useMutationCompanyCreate = () => {
  const { invalidate } = useCompanyInvalidation()
  const { defaultMutationHandler } = useDefaultMutationHandler()

  return useMutation({
    mutationFn(company: NewCompanyData) {
      const url = createUrl(Endpoint.Companies)
      return universalTypedFetch(url, companySchema, {
        method: 'POST',
        body: stringifyBody({ company }),
      })
    },
    onSettled: invalidate,
    ...defaultMutationHandler,
  })
}

export const resetOnboarding = async () => {
  const url = createUrl(Endpoint.Company, 'onboarding_reset')
  const options = { method: 'delete' }

  await fetch(url, options)
  location.reload()
}

const useCompanyInvalidation = useComposeInvalidation(() => [
  [Endpoint.Companies],
])
