<script setup lang="ts">
  import { onMounted } from 'vue'
  import TheBanner from '@/modules/app/components/TheBanner.vue'
  import TheMenu from '@/modules/app/components/TheMenu.vue'
  import { useLayoutStore } from '@/modules/base/stores/useLayoutStore'

  const layout = useLayoutStore()

  onMounted(() => {
    layout.isMainLayoutMounted = true
  })
</script>

<template>
  <div class="grid h-screen grid-cols-[auto,minmax(0,1fr)]">
    <div class="z-30 col-span-full">
      <TheBanner />
    </div>

    <TheMenu />

    <main
      ref="main"
      class="@container/main gc-global-main-view-transition-name gutter-stable relative col-start-2 col-end-3 flex flex-col overflow-auto"
    >
      <slot />
      <div id="actions-legend-teleport-target" class="contents" />
    </main>
  </div>
</template>
